<template>
  <v-card v-if="navigation" :class="cardData.class" elevation="0">
    <v-row no-gutters class="mt-3 ml-4">
      <v-icon
        color="primary"
        class="primary lighten-2 pa-2"
        large
        v-if="cardData.icon"
        >{{ `$${cardData.icon}` }}</v-icon
      >
    </v-row>
    <v-card-title class="pb-0" :class="cardData.icon ? 'pt-1' : 'mt-3'">
      {{ $t(cardData.title) }}
    </v-card-title>
    <v-card-subtitle class="mt-1">
      <div
        class="description text-body-2 text-left"
        v-if="cardData.description"
      >
        {{ $t(cardData.description) }}
      </div>
      <!-- handling counter for donateTree -->
      <div
        v-if="cardData.name === 'donateTree'"
        class="text-left counter"
        v-html="`${counter} ${$t('dashboard.points')}`"
      />
      <div class="text-body-1 text-left mt-2" v-if="cardData.label">
        <div v-if="counter" class="d-flex flex-row align-center">
          <span class="counter" v-html="counter" /><span
            class="label ml-2"
            v-html="$t(cardData.label)"
          />
        </div>
        <span v-else class="label">{{ $t(cardData.label) }}</span>
      </div>
    </v-card-subtitle>
    <v-card-actions class="d-flex justify-end">
      <router-link :to="{ name: cardData.routeName }">{{
        $t(cardData.link)
      }}</router-link>
      <v-icon color="primary">$chevronRight</v-icon>
    </v-card-actions>
  </v-card>
  <v-card
    v-else-if="cardData.name === 'selfscanning'"
    @click.stop="openSelfscanning"
    :class="cardData.class"
    elevation="0"
  >
    <v-row no-gutters class="mt-3 ml-4">
      <v-icon
        color="primary"
        class="primary lighten-2 pa-2"
        large
        v-if="cardData.icon"
        >{{ `$${cardData.icon}` }}</v-icon
      >
    </v-row>
    <v-card-title class="pb-0" :class="cardData.icon ? 'pt-1' : 'mt-3'">
      {{ $t(cardData.title) }}
    </v-card-title>
    <v-card-subtitle class="mt-1">
      <div
        class="description text-body-2 text-left"
        v-if="cardData.description"
      >
        {{ $t(cardData.description) }}
      </div>
      <!-- handling counter for donateTree -->
      <div
        v-if="cardData.name === 'donateTree'"
        class="text-left counter"
        v-html="`${counter} ${$t('dashboard.points')}`"
      />
      <div class="text-body-1 text-left mt-2" v-if="cardData.label">
        <div v-if="counter" class="d-flex flex-row align-center">
          <span class="counter" v-html="counter" /><span
            class="label ml-2"
            v-html="$t(cardData.label)"
          />
        </div>
        <span v-else class="label">{{ $t(cardData.label) }}</span>
      </div>
    </v-card-subtitle>
    <v-card-actions class="d-flex justify-end">
      <router-link :to="{ name: cardData.routeName }">{{
        $t(cardData.link)
      }}</router-link>
      <v-icon color="primary">$chevronRight</v-icon>
    </v-card-actions>
  </v-card>
  <v-card v-else @click.stop="doLogout" :class="cardData.class" elevation="0">
    <v-row no-gutters class="mt-3 ml-4">
      <v-icon
        color="primary"
        class="primary lighten-2 pa-2"
        large
        v-if="cardData.icon"
        >{{ `$${cardData.icon}` }}</v-icon
      >
    </v-row>
    <v-card-title class="pb-0" :class="cardData.icon ? 'pt-1' : 'mt-3'">
      {{ $t(cardData.title) }}
    </v-card-title>
    <v-card-subtitle class="mt-1">
      <div
        class="description text-body-2 text-left"
        v-if="cardData.description"
      >
        {{ $t(cardData.description) }}
      </div>
      <!-- handling counter for donateTree -->
      <div
        v-if="cardData.name === 'donateTree'"
        class="text-left counter"
        v-html="`${counter} ${$t('dashboard.points')}`"
      />
      <div class="text-body-1 text-left mt-2" v-if="cardData.label">
        <div v-if="counter" class="d-flex flex-row align-center">
          <span class="counter" v-html="counter" /><span
            class="label ml-2"
            v-html="$t(cardData.label)"
          />
        </div>
        <span v-else class="label">{{ $t(cardData.label) }}</span>
      </div>
    </v-card-subtitle>
    <v-card-actions class="d-flex justify-end">
      <router-link :to="{ name: cardData.routeName }">{{
        $t(cardData.link)
      }}</router-link>
      <v-icon color="primary">$chevronRight</v-icon>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">
.v-card {
  height: 180px;
  border: 1px solid var(--v-accent-base);
  .v-card__title {
    font-size: 28px;
  }
  .description {
    font-weight: 600;
  }
  .v-icon {
    font-size: 24px !important;
    border-radius: $border-radius-root;
  }
  .v-card__actions {
    position: absolute;
    bottom: 4px;
    right: 1px;
    a {
      color: var(--v-primary-base);
      text-decoration: none;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";
import Selfscanning from "@/views/selfscanning/Selfscanning.vue";

export default {
  name: "DashboardCard",
  props: {
    cardData: { type: Object, required: true },
    counter: { type: Number, required: false, default: 0 },
    navigation: { type: Boolean, required: true }
  },
  methods: {
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    },
    async openSelfscanning() {
      await this.$dialog.show(Selfscanning, { fullscreen: true });
    }
  }
};
</script>
