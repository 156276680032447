<template>
  <v-container fluid v-if="user" class="dashboard secondary--text text-center">
    <h1>{{ `Ciao ${user.firstName}` }}</h1>
    <h2 class="mb-4 mb-sm-0">{{ $t("dashboard.subtitle") }}</h2>
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="(card, idx) in cards"
        :key="idx"
        class="pa-2"
      >
        <DashboardCard
          :navigation="card.navigation"
          :cardData="card"
          :counter="getPoints(card.name)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.dashboard {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 20px;
    font-weight: normal;
    line-height: 30px;
  }
  .fidelityPoints {
    background-color: #000;
    .v-card__title {
      color: $white !important;
    }
    .v-card__subtitle {
      color: $white !important;
      .counter {
        font-size: 35px;
        color: var(--v-primary-base);
        font-weight: bold;
      }
    }
  }
  .donateTree,
  .donatePoints {
    .v-card__title {
      color: $white !important;
    }
    .v-card__subtitle {
      color: $white !important;
      .counter {
        font-size: 35px;
        color: $white;
        font-weight: bold;
        margin-top: 10px;
      }
    }
    a,
    .v-icon {
      color: $white !important;
    }
  }

  .donateTree {
    background-image: url("/img_layout/menu/card/sostenibilita/dona-un-albero.png");
    background-size: cover;
  }
  .donatePoints {
    background-color: #da1b5c;
  }
}
</style>
<script>
// @ is an alias to /src
import RegistrationService from "~/service/userService";
import DashboardCard from "@/components/profile/DashboardCard.vue";
import { mapActions } from "vuex";

export default {
  name: "Dashboard",
  components: { DashboardCard },
  data() {
    return {
      user: null,
      cards: [
        // {
        //   name: "fidelityPoints",
        //   title: "dashboard.fidelityPoints.title",
        //   description: "",
        //   label: "dashboard.fidelityPoints.label",
        //   icon: "",
        //   navigation: true,
        //   routeName: "#",
        //   class: "fidelityPoints",
        //   link: "dashboard.fidelityPoints.link"
        // },
        // {
        //   name: "premialì",
        //   title: "dashboard.premiali.title",
        //   description: "dashboard.premiali.description",
        //   icon: "premiali",
        //   routeName: "RewardsCart",
        //   link: "dashboard.premiali.link"
        // },
        // {
        //   name: "createOffer",
        //   title: "dashboard.createOffer.title",
        //   description: "dashboard.createOffer.description",
        //   icon: "tag",
        //   navigation: true,
        //   routeName: "CreateOffer",
        //   link: "dashboard.createOffer.link"
        // },
        // {
        //   name: "gifts",
        //   title: "dashboard.gifts.title",
        //   description: "dashboard.gifts.description",
        //   icon: "percent",
        //   navigation: true,
        //   routeName: "ProfileGift",
        //   link: "dashboard.gifts.link"
        // },
        {
          name: "profile",
          title: "dashboard.profile.title",
          description: "dashboard.profile.description",
          icon: "profile",
          navigation: true,
          routeName: "ProfileSummary",
          link: "dashboard.profile.link"
        },
        {
          name: "orders",
          title: "dashboard.orders.title",
          description: "dashboard.orders.description",
          icon: "cart",
          navigation: true,
          routeName: "Orders",
          link: "dashboard.orders.link"
        },
        {
          name: "lists",
          title: "dashboard.lists.title",
          description: "dashboard.lists.description",
          icon: "heart",
          navigation: true,
          routeName: "Lists",
          link: "dashboard.lists.link"
        },
        {
          name: "addresses",
          title: "dashboard.addresses.title",
          description: "dashboard.addresses.description",
          icon: "indirizzi",
          navigation: true,
          routeName: "Addresses",
          link: "dashboard.addresses.link"
        },
        // {
        //   name: "cards",
        //   title: "dashboard.cards.title",
        //   description: "dashboard.cards.description",
        //   icon: "creditCard",
        //   navigation: true,
        //   routeName: "MyPaymentMethods",
        //   link: "dashboard.cards.link"
        // },
        // {
        //   name: "donateTree",
        //   title: "dashboard.donateTree.title",
        //   description: "dashboard.donateTree.description",
        //   icon: "",
        //   navigation: true,
        //   routeName: "#",
        //   class: "donateTree",
        //   link: "dashboard.donateTree.link"
        // },
        // {
        //   name: "donate",
        //   title: "dashboard.donatePoints.title",
        //   description: "dashboard.donatePoints.description",
        //   icon: "",
        //   navigation: true,
        //   routeName: "Donate",
        //   class: "donatePoints",
        //   link: "dashboard.donatePoints.link"
        // },
        {
          name: "news-list",
          title: "dashboard.news.title",
          description: "dashboard.news.description",
          icon: "bell",
          navigation: true,
          routeName: "NewsListPage",
          class: "News",
          link: "dashboard.news.link"
        },
        {
          name: "invoice",
          title: "dashboard.invoice.title",
          description: "dashboard.invoice.description",
          icon: "file",
          navigation: true,
          routeName: "Invoice",
          class: "Invoice",
          link: "dashboard.invoice.link"
        },
        {
          name: "paymentDueDate",
          title: "dashboard.paymentDueDate.title",
          description: "dashboard.paymentDueDate.description",
          icon: "error",
          navigation: true,
          routeName: "PaymentDueDate",
          class: "PaymentDueDate",
          link: "dashboard.paymentDueDate.link"
        },
        {
          name: "selfscanning",
          title: "dashboard.selfscanning.title",
          description: "dashboard.selfscanning.description",
          icon: "",
          navigation: false,
          routeName: "",
          link: "dashboard.selfscanning.link"
        },
        {
          name: "logout",
          title: "dashboard.logout.title",
          description: "dashboard.logout.description",
          icon: "logout",
          routeName: "",
          navigation: false,
          link: ""
        }
      ]
    };
  },
  async created() {
    await this.fetchUserData();
  },
  methods: {
    getPoints(cardName) {
      //TODO: verificare mappatura punti
      if (cardName === "fidelityPoints") return 100;
      if (cardName === "donateTree") return 50;
    },
    fetchUserData() {
      RegistrationService.getUserDetail().then(user => {
        this.user = user;
      });
    },
    ...mapActions({
      logout: "cart/doLogout"
    }),
    async doLogout() {
      await this.logout();
      this.$router.push({
        name: "Home",
        path: "/"
      });
    }
  }
};
</script>
